import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { setAgent } from './agentSlice';

const initialState = {
  agentsList: [],
  isSuccess: false,
  isLoading: false,
  error: null,
};

export const masterAgentFormSlice = createSlice({
  name: 'masterAgentForm',
  initialState,
  reducers: {
    setMasterAgentFormLoading: (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.error = null;
    },
    setMasterAgentFormSuccess: (state) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.error = null;
    },
    setMasterAgentFormError: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    setAgentsList: (state, action) => {
      state.agentsList = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setAgentsListError: (state, action) => {
      state.agentsList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    setAgentsListLoading: (state) => {
      state.agentsList = [];
      state.isLoading = true;
      state.error = null;
    },
    resetMasterAgentForm: (state) => {
      state.error = null;
      state.isLaoding = false;
      state.isSucccess = false;
    },
  },
});

export const {
  resetMasterAgentForm,
  setMasterAgentFormError,
  setMasterAgentFormLoading,
  setMasterAgentFormSuccess,
  setAgentsList,
  setAgentsListError,
  setAgentsListLoading,
} = masterAgentFormSlice.actions;

export const agentEditMasterAgent =
  (agent, masterAgent) => async (dispatch) => {
    dispatch(setMasterAgentFormLoading());
    getApi()
      .patch(`/api/agents/${agent.uuid}`, {
        uuid: agent.uuid,
        superAgent: { uuid: masterAgent.uuid },
        simNumbers: agent.simNumbers.map((simNumber) => ({
          uuid: simNumber.uuid,
          phoneNumber: simNumber.phoneNumber,
          category: simNumber.category,
          fieldAgent: { uuid: simNumber.fieldAgent?.uuid },
        })),
      })
      .then((response) => {
        console.log(response.data);
        dispatch(setAgent(response.data));
        dispatch(setMasterAgentFormSuccess());
      })
      .catch((err) => {
        dispatch(setMasterAgentFormError(getApiError(err)));
      });
  };

export const agentsListFetch = (filters) => async (dispatch) => {
  dispatch(setAgentsListLoading());
  getApi()
    .get('/api/agents', { params: filters })
    .then((response) => {
      dispatch(setAgentsList(response.data));
    })
    .catch((err) => {
      dispatch(setMasterAgentFormError(getApiError(err)));
    });
};
export const masterAgentFormReducer = masterAgentFormSlice.reducer;
