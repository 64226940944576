import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { makeDownload } from '@/common/api';

const initialState = {
  agentList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const agentListSlice = createSlice({
  name: 'agentList',
  initialState: initialState,
  reducers: {
    setAgents: (state, { payload }) => {
      const { lastFilter, agents } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.agentList = agents;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const agentsFetchUsingApi = (filters) =>
  getApi().get('/api/agents', { params: filters });

export const agentListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  agentsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setAgents({
          lastFilter: filters?.toString(),
          agents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentListExport = (filters) => (dispatch) => {
  getApi()
    .get('/api/agents/export', { params: filters, responseType: 'blob' })
    .then(makeDownload)
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const { setError, setLoading, setAgents } = agentListSlice.actions;

export const agentListReducer = agentListSlice.reducer;
