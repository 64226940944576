import {
  agentReducer as agent,
  agentAssignFormReducer as agentAssignForm,
  agentConfigurationReducer as agentConfiguration,
  agentConfigurationFormReducer as agentConfigurationForm,
  agentContractFormReducer as agentContractForm,
  agentFormReducer as agentForm,
  agentListReducer as agentList,
  agentMergeFormReducer as agentMergeForm,
  pinFormReducer as pinForm,
  simNumberFormReducer as simNumberForm,
  simNumberListReducer as simNumberList,
  agentFieldAgentLogListReducer as agentFieldAgentLogList,
  masterAgentFormReducer as masterAgentForm,
} from '@/agent';
import { authReducer as auth } from '@/auth';
import {
  expenseReducer as expense,
  expenseDeleteReducer as expenseDelete,
  expenseFormReducer as expenseForm,
  expenseListReducer as expenseList,
  expenseTypeListReducer as expenseTypeList,
} from '@/expense';
import {
  fieldAgentReducer as fieldAgent,
  fieldAgentAssignFormReducer as fieldAgentAssignForm,
  fieldAgentFormReducer as fieldAgentForm,
  fieldAgentListReducer as fieldAgentList,
  fieldAgentManagerLogListReducer as fieldAgentManagerLogList,
  fieldAgentPositionFormReducer as fieldAgentPositionForm,
  fieldAgentPositionListReducer as fieldAgentPositionList,
  fieldAgentReassignFormReducer as fieldAgentReassignForm,
  fieldAgentTerritoriesReducer as fieldAgentTerritories,
  fieldAgentTerritoryDataReducer as fieldAgentTerritoryData,
} from '@/fieldAgent';
import {
  flagReducer as flag,
  flagFormReducer as flagForm,
  flagListReducer as flagList,
} from '@/flag';
import {
  loanReducer as loan,
  loanContractReducer as loanContract,
  loanContractFormReducer as loanContractForm,
  loanContractsReducer as loanContractList,
  loanDurationReducer as loanDuration,
  loanDurationFormReducer as loanDurationForm,
  loanDurationListReducer as loanDurationList,
  loanListReducer as loanList,
  loanPaymentsReducer as loanPaymentList,
  loanProductReducer as loanProduct,
  loanProductFormReducer as loanProductForm,
  loanProductListReducer as loanProductList,
  loanRepaymentsReducer as loanRepaymentList,
  loanRequestReducer as loanRequest,
  loanRequestFormReducer as loanRequestForm,
  loanRequestListReducer as loanRequestList,
  loanRuleReducer as loanRule,
  loanRuleFormReducer as loanRuleForm,
  loanRuleListReducer as loanRuleList,
} from '@/loan';
import { locationsReducer as locations } from '@/location';
import {
  notificationReducer as notification,
  notificationFormReducer as notificationForm,
  notificationListReducer as notificationList,
} from '@/notification';
import {
  actionReducer as action,
  actionFormReducer as actionForm,
  actionListReducer as actionList,
  parserReducer as parser,
  parserFormReducer as parserForm,
  parserListReducer as parserList,
  transactionReducer as transaction,
  transactionActivityReducer as transactionActivity,
  transactionFormReducer as transactionForm,
  transactionListReducer as transactionList,
} from '@/transaction';
import {
  userReducer as user,
  userFormReducer as userForm,
  userListReducer as userList,
} from '@/user';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import {
  cashDepositFormReducer as cashDepositForm,
  cashTransactionFormReducer as cashTransactionForm,
  floatCashCollectionFormReducer as floatCashCollectionForm,
  floatCashDisburseReducer as floatCashDisburse,
  floatCashListReducer as floatCashList,
  floatCashReviewReducer as floatCashReview,
  floatCashThresholdDetailsReducer as floatCashThreshold,
  floatCashThresholdListReducer as floatCashThresholdList,
  floatCashTransactionReducer as floatCashTransaction,
  floatCashTransactionEditFormReducer as floatCashTransactionEditForm,
  floatManagerAccountListReducer as floatManagerAccountList,
  floatRequestReducer as floatRequest,
  floatRequestListReducer as floatRequestList,
  settingsReducer as settings,
  varianceFormReducer as varianceForm,
  varianceListReducer as varianceList,
  varianceTransactionReducer as varianceTransaction,
} from './float-cash';
import { exchangeRateSettingsReducer as exchangeRateSettings } from '@/exchange';

import {
  customerReducer as customer,
  customerContractReducer as customerContract,
  customerContractFormReducer as customerContractForm,
  customerListReducer as customerList,
} from '@/customer';

import {
  dailyReportListReducer as dailyReportList,
  dailyReportDetailReducer as dailyReport,
} from '@/dailyReport';

import { dashboardConfigReducer as dashboardConfig } from '@/dashboardConfiguration';

import { pushNotificationListReducer as pushNotificationList } from '@/pushNotification';

import { commissionReducer as commissionList } from '@/fieldAgent/commissions';
import { reassignmentListReducer as reassignmentList } from './fieldAgent/manageTerritories/reassignments';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const store = configureStore({
  reducer: {
    router: routerReducer,
    action,
    actionForm,
    actionList,
    agent,
    agentAssignForm,
    agentConfiguration,
    agentConfigurationForm,
    agentContractForm,
    agentForm,
    agentList,
    agentMergeForm,
    masterAgentForm,
    auth,
    cashDepositForm,
    cashTransactionForm,
    commissionList,
    customer,
    customerContract,
    customerContractForm,
    customerList,
    dailyReportList,
    dailyReport,
    dashboardConfig,
    exchangeRateSettings,
    expense,
    expenseDelete,
    expenseForm,
    expenseList,
    expenseTypeList,
    fieldAgent,
    fieldAgentAssignForm,
    floatManagerAccountList,
    fieldAgentForm,
    fieldAgentList,
    fieldAgentManagerLogList,
    fieldAgentPositionForm,
    fieldAgentPositionList,
    fieldAgentReassignForm,
    fieldAgentTerritories,
    fieldAgentTerritoryData,
    flag,
    flagForm,
    flagList,
    floatCashCollectionForm,
    floatCashDisburse,
    floatCashList,
    floatCashReview,
    floatRequest,
    floatRequestList,
    floatCashTransaction,
    floatCashTransactionEditForm,
    floatCashThresholdList,
    floatCashThreshold,
    settings,
    loan,
    loanContract,
    loanContractForm,
    loanContractList,
    loanDuration,
    loanDurationForm,
    loanDurationList,
    loanList,
    loanPaymentList,
    loanProduct,
    loanProductForm,
    loanProductList,
    loanRepaymentList,
    loanRequest,
    loanRequestForm,
    loanRequestList,
    loanRule,
    loanRuleForm,
    loanRuleList,
    locations,
    notification,
    notificationForm,
    notificationList,
    parser,
    parserForm,
    parserList,
    pinForm,
    pushNotificationList,
    reassignmentList,
    simNumberForm,
    simNumberList,
    transaction,
    transactionActivity,
    transactionForm,
    transactionList,
    user,
    userForm,
    userList,
    varianceForm,
    varianceList,
    varianceTransaction,
    agentFieldAgentLogList,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware),
});

export const history = createReduxHistory(store);
