import { getApi, getApiError } from '@/common';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  agentFieldAgentLogList: [],
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const agentFieldAgentLogListSlice = createSlice({
  name: 'agentFieldAgentLogList',
  initialState: initialState,
  reducers: {
    setLogs: (state, { payload }) => {
      const { lastFilter, logs } = payload;
      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.agentFieldAgentLogList = logs;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const agentFieldAgentLogListFetchUsingApi = (agentUuid, filters) =>
  getApi().get(`/api/agents/${agentUuid}/field-agent-logs`, {
    params: filters,
  });

export const agentFieldAgentLogListFetch =
  (agentUuid, filters) => (dispatch) => {
    dispatch(setLoading(true));
    agentFieldAgentLogListFetchUsingApi(agentUuid, filters)
      .then((response) =>
        dispatch(
          setLogs({
            lastFilter: filters?.toString(),
            logs: response.data.data,
          })
        )
      )
      .catch((err) => dispatch(setError(getApiError(err))));
  };

export const { setError, setLoading, setLogs } =
  agentFieldAgentLogListSlice.actions;

export const agentFieldAgentLogListReducer =
  agentFieldAgentLogListSlice.reducer;
