import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { queryFilters } from './filter';

/** Get URL search query
 *
 * @returns {URLSearchParams}
 */
export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * Get filter (URL search query) state and callback to update it.
 *
 *
 * @returns {[URLSearchParams, (URLSearchParams)=>{}]}
 */
export const useFilters = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [filters, setFilters] = useState(new URLSearchParams(search));

  // console.log('useFilter: ', { search, filters: filters.toString() });

  useEffect(() => {
    const query = search.startsWith('?') ? search.substring(1) : search;
    if (query !== filters.toString()) {
      setFilters(new URLSearchParams(search));
    }
  }, [search, filters]);

  return [
    filters,
    (filters) => {
      navigate({ search: queryFilters(filters) });
      setFilters(new URLSearchParams(filters));
    },
  ];
};

/** Always run on 1st page load */
export const useInitial = (effect) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

/** Only run after 1st page load */
export const useUpdated = (effect, deps) => {
  const isFirstLoad = useRef(true);

  useEffect(
    () => {
      if (isFirstLoad.current) {
        isFirstLoad.current = false;
      } else {
        effect();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
};

/**
 * Get the dimensions of an HTML DOM element
 * @param {*} myRef React Reference
 * @returns
 */
export const useResize = (myRef) => {
  const [offsetTop, setOffsetTop] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(() => {
    setOffsetTop(myRef.current.offsetTop);
    setWidth(myRef.current.offsetWidth);
    setHeight(myRef.current.offsetHeight);
  }, [myRef]);

  useEffect(() => {
    if (myRef.current) {
      setOffsetTop(myRef.current.offsetTop);
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
      myRef.current.addEventListener('resize', handleResize);
    }

    const current = myRef.current;

    return () => {
      current?.removeEventListener('resize', handleResize);
    };
  }, [myRef, handleResize]);

  return { width, height, offsetTop };
};

/**
 /**
 * useDebounce - A custom React hook that delays updating a value until a specified delay has passed
 * 
 * This hook is useful for delaying API calls or expensive operations that don't need to happen
 * on every keystroke or state change. It helps to optimize performance by reducing the frequency
 * of operations when dealing with rapidly changing values like search inputs.
 * 
 * @param {any} value - The value to be debounced (typically a string for search inputs)
 * @param {number} delay - The delay in milliseconds before the value updates
 * @returns {any} - The debounced value that updates only after the specified delay has elapsed
 * 
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
