/** Feature flags to disable / enable different functionality in different environments */
export const FEATURES = Object.freeze({
  AGENT: 'agent',
  AGENT_COMPARE: 'agent:compare',
  AGENT_COMPLIANCE: 'agent:compliance',
  MANAGE_SUPER_AGENT: 'agent:manage-super-agent',
  AGENT_CONFIGURATION: 'agent:configuration',
  AGENT_DEPOSIT_DIALOG: 'agent_deposit_dialog',
  AGENT_EXPENSE: 'agent:expense',
  AGENT_EXPERIMENTATION: 'agent:experimentation',
  AGENT_EXTERNAL_ID: 'agent:external-id',
  AGENT_SC_CODE: 'agent:sc-code',
  AGENT_KNOWLEDGE_CENTER: 'agent:knowledge-center',
  AGENT_PIN: 'agent:pin',
  AGENT_SHOW_ACTIVE: 'agent:show-active',
  AGENT_TRADING_NAME: 'agent:trading-name',
  AGENT_VERIFICATION: 'agent:verification',
  CURRENCY_EXCHANGE: 'currency:exchange',
  CUSTOMER_REGISTRATION: 'customer_registration',
  DEPOSIT_EXTERNAL_ID: 'deposit-transaction-id',
  DISBURSE_FROM_LINE: 'float-control:disburse-from-line',
  FIELD_AGENT: 'field-agent',
  FIELD_AGENT_COMMISSION: 'field-agent:commission',
  FIELD_AGENT_EXPENSE: 'field-agent:expense',
  FIELD_AGENT_REASSIGNMENTS: 'field-agent:reassignments',
  FIELD_AGENT_TERRITORY: 'field-agent:territory',
  FLOAT_CONTROL: 'float-control',
  FLOAT_CONTROL_REMITTANCES: 'float-control:remittances',
  FLOAT_EXCHANGE: 'float-exchange',
  FLOAT_REQUESTS_COMMISSION_BUCKET: 'float-requests:commission-bucket',
  FLOAT_REQUESTS_FLOAT_UTILIZATION: 'float-requests:float-utilization',
  LOAN: 'loan',
  NOTIFICATION_CENTER: 'notification-center',
  REPORT: 'report',
  TRANSACTION: 'transaction',
  TRANSACTION_UG: 'transaction-ug',
  TRANSACTION_AIRTIME: 'transaction:airtime',
  TRANSACTION_BILLPAY: 'transaction:billpay',
  TRANSACTION_BILLPAY_AO: 'transaction:billpay-ao',
  TRANSACTION_BILLPAY_UG: 'transaction:billpay-ug',
  TRANSACTION_BUNDLE: 'transaction:bundle',
  TRANSACTION_CASH_IN: 'transaction:cash-in',
  TRANSACTION_CASH_OUT: 'transaction:cash-out',
  TRANSACTION_EXTERNAL_ID: 'transaction:external-id',
  TRANSACTION_SOURCE_SHEET: 'transaction:source-sheet',
  /** Transaction records come from SMS*/
  TRANSACTION_SOURCE_SMS: 'transaction:source-sms',
  TRANSACTION_SOURCE_EZEEMONEY: 'transaction:source-ezeemoney',
  TRANSACTION_SOURCE_USSD: 'transaction:source-ussd',
  TRANSACTION_SOURCE_WEB: 'transaction:source-web',
  TRANSACTION_TELCO: 'transaction:telco',
  TRANSACTION_TRANSFER: 'transaction:transfer',
  USER: 'user',
  USSD: 'ussd',
  WALLET: 'wallet',
});
const oparetaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPARE,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_SC_CODE,
  FEATURES.AGENT_VERIFICATION,
  FEATURES.DEPOSIT_EXTERNAL_ID,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_REASSIGNMENTS,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_EXPENSE,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.FLOAT_REQUESTS_COMMISSION_BUCKET,
  FEATURES.FLOAT_REQUESTS_FLOAT_UTILIZATION,
  FEATURES.FLOAT_EXCHANGE,
  FEATURES.LOAN,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_UG,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BILLPAY,
  FEATURES.TRANSACTION_BILLPAY_UG,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.TRANSACTION_SOURCE_EZEEMONEY,
  FEATURES.TRANSACTION_SOURCE_SHEET,
  FEATURES.TRANSACTION_SOURCE_USSD,
  FEATURES.USER,
  FEATURES.WALLET,
];
const oparetaStagingFeatures = [...oparetaFeatures];
const afrimoneyAngolaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_KNOWLEDGE_CENTER,
  FEATURES.AGENT_SHOW_ACTIVE,
  FEATURES.DISBURSE_FROM_LINE,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_REASSIGNMENTS,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FLOAT_CONTROL,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.TRANSACTION_BILLPAY_AO,
  FEATURES.TRANSACTION_SOURCE_USSD,
  FEATURES.USER,
];
const afrimoneyAngolaStagingFeatures = [...afrimoneyAngolaFeatures];
const afrimoneyGambiaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_KNOWLEDGE_CENTER,
  FEATURES.AGENT_SHOW_ACTIVE,
  FEATURES.DISBURSE_FROM_LINE,
  FEATURES.CUSTOMER_REGISTRATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_REASSIGNMENTS,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.TRANSACTION_SOURCE_USSD,
  FEATURES.USER,
];
const afrimoneyGambiaStagingFeatures = [...afrimoneyGambiaFeatures];
const afrimoneySierraLeoneFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_SHOW_ACTIVE,
  FEATURES.DISBURSE_FROM_LINE,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_REASSIGNMENTS,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.MANAGE_SUPER_AGENT,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.TRANSACTION_SOURCE_USSD,
  FEATURES.USER,
];
const afrimoneySierraLeoneStagingFeatures = [...afrimoneySierraLeoneFeatures];
const cyadDrcFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_DEPOSIT_DIALOG,
  FEATURES.DISBURSE_FROM_LINE,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_REASSIGNMENTS,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.TRANSACTION_SOURCE_USSD,
  FEATURES.USER,
  FEATURES.CURRENCY_EXCHANGE,
];
const cyadDrcStagingFeatures = [...cyadDrcFeatures];
const mobicomZambiaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPARE,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_TRADING_NAME,
  FEATURES.AGENT_VERIFICATION,
  FEATURES.DISBURSE_FROM_LINE,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_REASSIGNMENTS,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_EXPENSE,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.FLOAT_EXCHANGE,
  FEATURES.LOAN,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const mobicomZambiaStagingFeatures = [...mobicomZambiaFeatures];
const sierraLeoneFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPARE,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_VERIFICATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_EXPENSE,
  FEATURES.FIELD_AGENT_REASSIGNMENTS,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.FLOAT_CONTROL_REMITTANCES,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.TRANSACTION_SOURCE_WEB,
  FEATURES.TRANSACTION_SOURCE_USSD,
  FEATURES.USER,
];
const sierraLeoneStagingFeatures = [...sierraLeoneFeatures];

export const getFeatures = (env) => {
  env = env ?? import.meta.env?.VITE_ENV;
  switch (env) {
    case 'staging_sl':
      return sierraLeoneStagingFeatures;
    case 'staging_afrimoney_angola':
      return afrimoneyAngolaStagingFeatures;
    case 'staging_afrimoney_gambia':
      return afrimoneyGambiaStagingFeatures;
    case 'staging_afrimoney_sl':
      return afrimoneySierraLeoneStagingFeatures;
    case 'staging_mobicom_zambia':
      return mobicomZambiaStagingFeatures;
    case 'staging_cyad_drc':
      return cyadDrcStagingFeatures;
    case 'staging_opareta':
      return oparetaStagingFeatures;
    case 'production_sl':
      return sierraLeoneFeatures;
    case 'production_afrimoney_angola':
      return afrimoneyAngolaFeatures;
    case 'production_afrimoney_gambia':
      return afrimoneyGambiaFeatures;
    case 'production_afrimoney_sl':
      return afrimoneySierraLeoneFeatures;
    case 'production_cyad_drc':
      return cyadDrcFeatures;
    case 'production_mobicom_zambia':
      return mobicomZambiaFeatures;
    case 'production_opareta':
    default:
      return oparetaFeatures;
  }
};

export const hasFeature = (feature) =>
  !feature || getFeatures().indexOf(feature) > -1;
